import './App.css';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import logo from './logo192.png';

import BorderLayout from './components/BorderLayout';
import { useEffect, useState } from 'react';

const Top = () => (
  <div className="text-light bg-primary py-4">
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex">
        <img src={logo} alt="logo" width="80" />
        <div className="ms-3">
          <h1>Yu-Gi-Oh どろー計算機</h1>
          <div>遊戯王 デュエルリンクス のドロー確率を ゆるーく 計算できます</div>
        </div>
      </div>
    </div>
  </div>
);

const Bottom = () => (
  <div className="pt-3 d-flex flex-column align-items-center justify-content-center" style={{ color: 'gray', fontSize: 12, backgroundColor: '#1d6a9620' }}>
    <a href="https://neko-note.org/">「それなら猫の手で」</a>
    <div className="mb-1">Copyright © 2021</div>
    <small>
      <div className="text-center mb-1">
        免責事項<br />
        コンテンツについて、掲載内容の正確性・完全性・信頼性・最新性は保証しておりません。また、掲載されている情報を利用することで発生した紛争や損害に対し、責任を負わないものとします。
      </div>
    </small>
  </div>
);

const MyCard = (props) => {
  const {
    name,
    onChangeName,
    value,
    setValue,
  } = props;

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="me-4" style={{ minWidth: 64 }}>
        <MyInput value={name} onChange={onChangeName} />
      </div>
      <div className="d-flex my-1">
        {
          [0, 1, 2, 3].map(xx => {
            return (
              <span className="mx-1">
                {
                  xx === value
                    ? (
                      <Button size="sm" variant="secondary" style={{ minWidth: 36 }}>{xx}</Button>
                    )
                    : (
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        style={{ minWidth: 36, opacity: 0.5 }}
                        onClick={() => setValue(xx)}
                      >
                        {xx}
                      </Button>
                    )
                }
              </span>
            );
          })
        }
      </div>
    </div>
  );
};

const MyInput = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <Form.Control size="sm" type="text" placeholder="カード名" value={value} onChange={onChange} />
  );
};

const create = (p, c) => {
  if (p.length === 0) {
    return c.map(x => [x]);
  }
  return p.reduce((a, v1) => {
    c.forEach(v2 => {
      if (v1 === v2 || (Array.isArray(v1) && v1.indexOf(v2) !== -1)) {
        return;
      }
      if (v2 < (Array.isArray(v1) ? v1[v1.length - 1] : v1)) {
        return;
      }
      a.push([].concat(v1, v2));
    });
    return a;
  }, []);
};

const createCombination = (...arr) => {
  return arr.reduce(create, []);
};

function App() {
  const [deck, setDeck] = useState(20);
  const [draw, setDraw] = useState(4);
  const [patterns, setPatterns] = useState([]);

  const [cardNames, setCardNames] = useState([
    "カード1",
    "カード2",
    "カード3",
    "カード4",
    "カード5",
    "カード6",
    "カード7",
    "カード8",
    "カード9",
    "カード10",
    "カード11",
    "カード12",
    "カード13",
    "カード14",
    "カード15",
  ]);
  const [cards, setCards] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const condCount = 100;

  const [condCards, setCondCards] = useState([...Array(condCount * 5)].map(() => -1));
  const [condQuantitys, setCondQuantitys] = useState([...Array(condCount * 5)].map(() => 1));
  const [condCompares, setCondCompares] = useState([...Array(condCount * 5)].map(() => "ge"));
  const [condPlaces, setCondPlaces] = useState([...Array(condCount * 5)].map(() => "draw"));

  useEffect(() => {
    const deckIndexes = [...Array(deck)].map((x, i) => i);
    const array = createCombination(...Array(draw).fill(deckIndexes));
    // console.log(array.length);
    setPatterns(array);
  }, [draw, deck])

  const condIndexes = [...Array(condCount)].map((x, i) => i);

  const condCards2d = condIndexes.map(x => condCards.slice(x * 5, (x + 1) * 5));
  const condQuantitys2d = condIndexes.map(x => condQuantitys.slice(x * 5, (x + 1) * 5));
  const condCompares2d = condIndexes.map(x => condCompares.slice(x * 5, (x + 1) * 5));
  const condPlaces2d = condIndexes.map(x => condPlaces.slice(x * 5, (x + 1) * 5));

  const kinds = cards.map((card, i) => [...Array(card)].map(() => i)).reduce((p, c) => p.concat(c), []);
  const deckCardKind = [...Array(deck)].map((x, i) => kinds[i] !== undefined ? kinds[i] : -1);
  // console.log(deckCardKind)

  const remainPatternsByCond = condIndexes.map(condIndex => {
    if (condCards2d[condIndex].every(condCard => condCard === -1)) {
      return [];
    }

    let remainPatterns = [...patterns];
    condCards2d[condIndex].forEach((condCard, i) => {
      // condCard は 目的のカード番号
      if (condCard === -1) {
        return;
      }
      remainPatterns = remainPatterns.filter(p => {
        // p: [0,1,2,3,4] みたいな手持ちのカードのインデックス
        const count = p.filter(x => deckCardKind[x] === condCard).length;

        const compare = {
          eq: (count) => condQuantitys2d[condIndex][i] === count,
          ge: (count) => condQuantitys2d[condIndex][i] <= count,
        }

        const placeToCount = {
          draw: (count) => count,
          // そのカードがデッキに入っている枚数は cards[condCard]
          remain: (count) => cards[condCard] - count,
        }

        return compare[condCompares2d[condIndex][i]](placeToCount[condPlaces2d[condIndex][i]](count));
      })
    })
    // console.log(remainPatterns);
    return remainPatterns;
  });

  const remainPatterns = [...new Set(remainPatternsByCond.map(x => x.map(xx => xx.join("-"))).reduce((p, c) => p.concat(c), []))];
  // console.log(remainPatterns);

  const center = (
    <div className="d-flex flex-column pt-4 pb-5 px-4" style={{ /*minHeight: "calc(100vh - 1.8vw - 1.7rem - 0.67em - 162px)"*/ }}>
      <Container fluid className="pb-3">
        <Row>
          <Col xs={12} className="mb-2">
            <div className="d-flex">
              <b>概要</b>
              <div className="ms-4" style={{ fontSize: 12, opacity: 0.8 }}>
                このページは、 遊戯王 デュエルリンクス のプレイヤーがさまざまな条件での ドロー確率 を計算できるものです。<br />
                ボタンをポチポチしたり、ドロップダウンから選んだりすると、計算結果に反映されていきます。<br />
                自由に使っていただいて構いません。
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center my-1">
              <div className="me-4" style={{ width: 128 }}>
                <b>デッキ枚数</b>
              </div>
              {
                [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map(x => {
                  return (
                    <span className="mx-1">
                      {
                        x === deck
                          ? (
                            <Button variant="secondary" style={{ minWidth: 56 }} active>{x}</Button>
                          )
                          : (
                            <Button variant="outline-secondary" style={{ minWidth: 56, opacity: 0.5 }} onClick={() => setDeck(x)} >{x}</Button>
                          )
                      }
                    </span>
                  );
                })
              }
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12}>
            <b>デッキ詳細</b>
            <span className="ms-4" style={{ fontSize: 12, opacity: 0.8 }}>（後続の <b>条件</b> に絡まないカードは、指定を省略しても構いません）</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div className="my-1">
              {
                [1, 2, 3, 4, 5].map((x, i) => {
                  return (
                    <MyCard
                      name={cardNames[i]}
                      value={cards[i]}
                      setValue={v => {
                        const a = [...cards];
                        a[i] = v;
                        setCards(a);
                      }}
                      onChangeName={e => {
                        const a = [...cardNames];
                        a[i] = e.target.value;
                        setCardNames(a);
                      }}
                    />
                  );
                })
              }
            </div>
          </Col>
          <Col xs={4}>
            <div className="my-1">
              {
                [6, 7, 8, 9, 10].map((x, i) => {
                  return (
                    <MyCard
                      name={cardNames[i + 5]}
                      value={cards[i + 5]}
                      setValue={v => {
                        const a = [...cards];
                        a[i + 5] = v;
                        setCards(a);
                      }}
                      onChangeName={e => {
                        const a = [...cardNames];
                        a[i + 5] = e.target.value;
                        setCardNames(a);
                      }}
                    />
                  );
                })
              }
            </div>
          </Col>
          <Col xs={4}>
            <div className="my-1">
              {
                [11, 12, 13, 14, 15].map((x, i) => {
                  return (
                    <MyCard
                      name={cardNames[i + 10]}
                      value={cards[i + 10]}
                      setValue={v => {
                        const a = [...cards];
                        a[i + 10] = v;
                        setCards(a);
                      }}
                      onChangeName={e => {
                        const a = [...cardNames];
                        a[i + 10] = e.target.value;
                        setCardNames(a);
                      }}
                    />
                  );
                })
              }
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center my-1">
              <div className="me-4" style={{ width: 128 }}>
                <b>ドロー枚数</b>
              </div>
              {
                [1, 2, 3, 4, 5, 6].map(x => {
                  return (
                    <span className="mx-1">
                      {
                        x === draw
                          ? (
                            <Button variant="secondary" className="rounded-pill" style={{ minWidth: 112 }} active>{x}</Button>
                          )
                          : (
                            <Button variant="outline-secondary" className="rounded-pill" style={{ minWidth: 112, opacity: 0.5 }} onClick={() => setDraw(x)}>{x}</Button>
                          )
                      }
                    </span>
                  );
                })
              }
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mb-2">
          <Col xs={12}>
            <b>条件</b>
            <span className="ms-4" style={{ fontSize: 12, opacity: 0.8 }}>（OR で区切られていないところは AND の条件です)</span>
          </Col>
        </Row>
        {
          condIndexes.map(or => {
            if ((or !== 0) && condCards.slice((or - 1) * 5).every(x => x === -1)) {
              return undefined;
            }

            return (
              <>
                {
                  or !== 0 && (
                    <>
                      <hr />
                      <div className="d-flex justify-content-center">
                        <div className="position-relative px-2" style={{ color: '#777777', top: -24, backgroundColor: 'white', fontSize: 10 }}>OR</div>
                      </div>
                    </>
                  )
                }
                <Row>
                  <Col xs={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="flex-grow-1">
                        {
                          [0, 1, 2, 3, 4].map(x => {
                            if ((x !== 0) && condCards.slice(or * 5 + x - 1, or * 5 + 5).every(x => x === -1)) {
                              return undefined;
                            }
                            return (
                              <>
                                <div className="ms-4 d-flex align-items-center justify-content-between border-bottom">
                                  <div className="d-flex me-2 py-1">
                                    <Form.Select
                                      size="sm"
                                      value={condCards[or * 5 + x]}
                                      onChange={e => {
                                        const a = [...condCards];
                                        a[or * 5 + x] = e.target.value - 0;
                                        setCondCards(a);
                                      }}
                                      style={{ minWidth: 160 }}
                                    >
                                      <option value="-1">未設定</option>
                                      {
                                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(num => (
                                          <option value={num}>{cardNames[num]}</option>
                                        ))
                                      }
                                    </Form.Select>
                                  </div>
                                  {
                                    (condCards[or * 5 + x] !== -1)
                                      ? (
                                        <>
                                          <div className="d-flex justify-content-center mx-2">を</div>
                                          <div className="d-flex align-items-center my-1">
                                            {
                                              [0, 1, 2, 3].map(xx => {
                                                return (
                                                  <span className="mx-1">
                                                    {
                                                      xx === condQuantitys[or * 5 + x]
                                                        ? (
                                                          <Button size="sm" variant="primary">{xx}</Button>
                                                        )
                                                        : (
                                                          <Button
                                                            size="sm"
                                                            variant="outline-primary"
                                                            style={{ opacity: 0.5 }}
                                                            onClick={() => {
                                                              const a = [...condQuantitys];
                                                              a[or * 5 + x] = xx;
                                                              setCondQuantitys(a);
                                                            }}
                                                          >
                                                            {xx}
                                                          </Button>
                                                        )
                                                    }
                                                  </span>
                                                );
                                              })
                                            }
                                            枚
                                          </div>
                                          <div className="d-flex mx-2">
                                            {
                                              ["ぴったり", "以上"].map(xx => {
                                                const nameToData = {
                                                  "ぴったり": "eq",
                                                  "以上": "ge",
                                                };
                                                return (
                                                  <div className="mx-1">
                                                    {
                                                      nameToData[xx] === condCompares[or * 5 + x]
                                                        ? (
                                                          <Button size="sm" className="rounded-pill" variant="primary">{xx}</Button>
                                                        )
                                                        : (
                                                          <Button
                                                            size="sm"
                                                            className="rounded-pill"
                                                            variant="outline-primary"
                                                            style={{ opacity: 0.5 }}
                                                            onClick={() => {
                                                              const a = [...condCompares];
                                                              a[or * 5 + x] = nameToData[xx];
                                                              setCondCompares(a);
                                                            }}
                                                          >
                                                            {xx}
                                                          </Button>
                                                        )
                                                    }
                                                  </div>
                                                );
                                              })
                                            }
                                          </div>
                                          <div className="d-flex mx-2">
                                            {
                                              ["ドローできる", "デッキに残す"].map(xx => {
                                                const nameToData = {
                                                  "ドローできる": "draw",
                                                  "デッキに残す": "remain",
                                                };
                                                return (
                                                  <div className="mx-1">
                                                    {
                                                      nameToData[xx] === condPlaces[or * 5 + x]
                                                        ? (
                                                          <Button size="sm" className="rounded-pill" variant="success">{xx}</Button>
                                                        )
                                                        : (
                                                          <Button
                                                            size="sm"
                                                            className="rounded-pill"
                                                            variant="outline-success"
                                                            style={{ opacity: 0.5 }}
                                                            onClick={() => {
                                                              const a = [...condPlaces];
                                                              a[or * 5 + x] = nameToData[xx];
                                                              setCondPlaces(a);
                                                            }}
                                                          >
                                                            {xx}
                                                          </Button>
                                                        )
                                                    }
                                                  </div>
                                                );
                                              })
                                            }
                                          </div>
                                        </>
                                      )
                                      : (
                                        <div></div>
                                      )
                                  }
                                </div>
                              </>
                            );
                          })
                        }
                      </div>
                      <div className="text-end px-2" style={{ minWidth: 192 }}>
                        <span style={{ fontSize: 32 }}>
                          {Math.round((remainPatternsByCond[or].length / patterns.length) * 10000) / 100}％
                        </span>
                        <br />
                        <small>
                          ({remainPatternsByCond[or].length} / {patterns.length} <small>通り</small>)
                        </small>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            );
          })
        }
        <hr />
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
              <span>いずれかの事象が起きる確率</span>
              <span style={{ fontSize: 32 }}>
                {Math.round((remainPatterns.length / patterns.length) * 10000) / 100}％
              </span>
              <small>
                ({remainPatterns.length} / {patterns.length} <small>通り</small>)
              </small>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );

  return (
    <div style={{ minWidth: 992 }}>
      <BorderLayout
        top={<Top />}
        center={center}
        bottom={<Bottom />}
      />
    </div>
  );
}

export default App;
